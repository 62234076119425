import { FaAngleDown, FaAngleUp }  from "react-icons/fa"
const prefijo = 'AGT'
export default function Inscripto (props) {

  function generar_QR(id) {
    fetch (props.back + 'descargar_qr/id/' + id)
    .then (response => response.json())
    .then (a => {if (a==='Ok') 
      window.open("https://inscripcion.ar/servicios/back_agtech/" + prefijo + id + '.png')
    })
  }

  
  return (<>
    <div className="flex flex-col bg-orange-200">
    <div className="flex flex-row justify-between items-center justify-items-start bg-orange-200" key={props.form.id}>
        <div className="flex flex-row w-full items-start">
            <div className="w-20 flex flex-row justify-between pr-5">
                <input 
                  type="checkbox" 
                  id={props.form.id}  
                  checked={props.obj.s}
                  onChange={(e => props.elegir(props.obj.id))}
                />
                {props.obj.id !== props.form.id ?
                  <FaAngleDown 
                    className="text-lg"
                    onClick={() => props.setForm(props.obj)}
                  />
                :
                  <FaAngleUp  
                    className="text-lg"
                    onClick={() => props.setForm({...props.obj, id: 0})}
                  />
                }
            </div>
            <div className="px-5 grid grid-cols-5 w-full">
              <div className="">{props.obj.apellido}</div>
              <div className="">{props.obj.nombre}</div>
              <div className="col-span-2">{props.obj.email}</div> 
              <div className="">{prefijo + (props.obj.id).toString().padStart(4,'0')}</div>
            </div>
        </div> 
        </div>
        {props.obj.id === props.form.id ?
          <div className="border-2 border-gray-600 w-full my-1 p-2 flex flex-col">
            <div className="flex flex-row justify-evenly my-1">
              <div className="flex flex-row justify-between w-30">
                <label>Apellido</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.apellido}
                  onChange={(e => props.setForm({...props.form, apellido: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
                <label>Nombre</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.nombre}
                  onChange={(e => props.setForm({...props.form, nombre: e.target.value}))}
                />
              </div>
              <div className="flex flex-row justify-between w-30">
               <label>Mail</label>
                <input 
                  className="ml-5"
                  type="text"
                  value={props.form.email}
                  onChange={(e => props.setForm({...props.form, email: e.target.value}))}
                />
              </div>
              </div>
              <div className="flex flex-row justify-evenly my-1">
              <div className="flex flex-row justify-between w-30">
                  <label className="font-lg">{'ID:  ' + prefijo + (props.obj.id).toString().padStart(4,'0')}</label>
              </div>
            </div>
              <div className="flex flex-row justify-around my-1">
                 <button 
                   name='Aceptar' 
                   className="border-2 px-2 border-blue-400 bg-blue-200 text-sm hover:text-white hover:bg-blue-800" 
                   onClick={() => props.update(props.form)}
                 >
                 Guardar Cambios
                 </button>
                 <button 
                   name='Generar' 
                   className="border-2 px-2 border-green-400 bg-green-200 text-sm hover:text-white hover:bg-blue-800" 
                   onClick={e => generar_QR(props.form.id)}
                 >
                 Ver QR
                 </button>
                 </div>
              </div>
        :
        ''
        }
    </div>
  </>)
}